export var ApplePayEventsEnum;
(function (ApplePayEventsEnum) {
  ApplePayEventsEnum["Loaded"] = "applePayLoaded";
  ApplePayEventsEnum["FailedToLoad"] = "applePayFailedToLoad";
  ApplePayEventsEnum["Completed"] = "applePayCompleted";
  ApplePayEventsEnum["Canceled"] = "applePayCanceled";
  ApplePayEventsEnum["Failed"] = "applePayFailed";
  ApplePayEventsEnum["DidSelectShippingContact"] = "applePayDidSelectShippingContact";
  ApplePayEventsEnum["DidCreatePaymentMethod"] = "applePayDidCreatePaymentMethod";
})(ApplePayEventsEnum || (ApplePayEventsEnum = {}));
