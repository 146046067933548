export var PaymentFlowEventsEnum;
(function (PaymentFlowEventsEnum) {
  PaymentFlowEventsEnum["Loaded"] = "paymentFlowLoaded";
  PaymentFlowEventsEnum["FailedToLoad"] = "paymentFlowFailedToLoad";
  PaymentFlowEventsEnum["Opened"] = "paymentFlowOpened";
  PaymentFlowEventsEnum["Created"] = "paymentFlowCreated";
  PaymentFlowEventsEnum["Completed"] = "paymentFlowCompleted";
  PaymentFlowEventsEnum["Canceled"] = "paymentFlowCanceled";
  PaymentFlowEventsEnum["Failed"] = "paymentFlowFailed";
})(PaymentFlowEventsEnum || (PaymentFlowEventsEnum = {}));
